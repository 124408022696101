/* eslint-disable no-unused-expressions */
// todo: remove after https://github.com/webpack-contrib/mini-css-extract-plugin/issues/34 resolved
if (process.env.NODE_ENV !== 'production') {
  import('@sass/main.scss');
}

import(/* webpackChunkName: "components" */ './components');

// Load react only if we have components on the page.
const components = document.querySelectorAll('[data-component]');

if (components.length) {
  import(/* webpackChunkName: "app" */ './react');
}
